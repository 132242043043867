import PrivateNetworkClient from "helpers/constants/privateNetwork";

const ProfileDetails = async (userId) => {
    if (localStorage.getItem('token') === null) {
        return null;
    }
    if(userId === null){
        return null;
    }
    const { data } = await PrivateNetworkClient.get(`/Role/RolePermission/UserId?userId=${userId}`);
    return data;
};

export {
    ProfileDetails,
};