const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

const fetchAwsImage = async (imgPath) => {
    const data = await fetch(imgPath, {
        method: "get",
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
    return data;
}


export {
    fetchAwsImage
};