import React, { Fragment, useEffect } from "react";
import { useAwsImage } from "store/hooks/awsImage/AwsImage";
import { Skeleton } from 'antd';


const PrivateImage = ({ className, privateUrl, shape, onClick }) => {
    const { data, refetch, isLoading } = useAwsImage(privateUrl);

    useEffect(() => {
        if (privateUrl !== undefined) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateUrl]);

    return (
        <Fragment>
            {
                !isLoading && privateUrl !== undefined && data !== undefined ?
                    <img className={className} src={data} alt="...." onClick={onClick} /> :  <Skeleton.Image  active="true" size="large" className={shape} />
            }
        </Fragment>
    )
};

export default PrivateImage;