export const AUTH = {
    TOKEN: "security-token",
    ENTRY_ROUTE: "/machine-inspection",
    TOKEN_PAYLOAD_KEY: "authorization",
    PUBLIC_REQUEST_KEY: "public-request",
    REFRESH_TOKEN: "refresh-token"
}

export const HEADER = {
    CONTENT_TYPE: 'application/json',
    MULTIPART_CONTENT_TYPE: 'multipart/form-data,boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
    TIMEOUT: 3000
}