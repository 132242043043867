import { createContext, useContext, useState } from "react";

const LocalContext = createContext();

export function useContextHelper() {
  return useContext(LocalContext);
}

function ContextProvider({ children }) {
  const [profile, setProfile] = useState([]);

  return (
    <LocalContext.Provider value={{ profile, setProfile }}>
      {children}
    </LocalContext.Provider>
  );
}

export default ContextProvider;

