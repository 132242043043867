const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;

export const fileDownloadBaseUrl = REACT_APP_BASE_API+"/Common/downloadFile?filePath=";
export const fileViewBaseUrl = REACT_APP_BASE_API+"/Common/getFile?filePath=";


export const statusColor = (value) => {
    if (value === "Active") {
        return "green-btn";
    } else if (value === "InActive") {
        return "red-btn";
    }
}